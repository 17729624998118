<template>
    <div class="ui-switch">
        <div class="ui-switch__container form-check form-switch">
            <input
                :id="id"
                class="ui-switch__input form-check-input"
                type="checkbox"
                role="switch"
                :disabled="disabled"
                v-model="checked"
                @input="handleChange"
            />
            <label v-if="label" class="form-check-label" :for="id">
                {{ label }}
            </label>
        </div>
        <div v-if="field && field.$error" class="ui-switch__error">
            {{ field.$errors[0].$message }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiSwitch',
    props: {
        debounce: {
            type: Number,
            default: 0,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        field: {
            type: Object,
            default: null,
            required: false,
        },
        id: {
            type: String,
            default: null,
            required: true,
        },
        label: {
            type: String,
            default: null,
            required: false,
        },
        name: {
            type: String,
            default: null,
            required: true,
        },
        value: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    data() {
        return {
            checked: this.value,
        };
    },
    watch: {
        value(value) {
            this.checked = value;
        },
    },
    methods: {
        handleChange() {
            clearTimeout(this.timer);

            if (this.debounce === 0) {
                this.updateValue();
            } else {
                this.timer = setTimeout(() => {
                    this.updateValue();
                }, this.debounce);
            }
        },
        updateValue() {
            const value = !this.checked;

            this.$emit('update:value', value);
        },
    },
};
</script>

<style lang="scss">
.ui-switch {
    &__container {
        margin-bottom: 0;
    }

    &__input {
        &:checked {
            background-color: $ecart-primary-400;
            border-color: $ecart-primary-400;
        }
    }

    &__error {
        color: $general-error;
        font-size: 0.9rem;
        margin-top: 5px;
    }
}
</style>
